import React, { lazy, Suspense, useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import PageLayout from 'sharedComponents/PageLayout';
import InfoViewSkeleton from 'sharedComponents/InfoViewSkeleton';
import useZendeskIdentify from 'sharedHooks/useZendeskIdentify';
import usePendoIdentify from 'sharedHooks/usePendoIdentify';

import { AuthContext } from './AuthProvider';
import ClientsPage from '../../pages/client';
import SchedulePage from '../../pages/schedule';
import MessagesPage from '../../pages/messages';
import DashboardPage from '../../pages/dashboard';

const VideoChatPage = lazy(() => import('../../pages/videoChat'));

function AuthRequiredRoutes(): JSX.Element {
  const { joinVideoChatFlag } = useFlags();
  const { loading } = useContext(AuthContext);
  useZendeskIdentify();
  usePendoIdentify();

  return (
    <Suspense fallback={<InfoViewSkeleton />}>
      <Switch>
        {joinVideoChatFlag && (
          <Route
            path="/connect/:eventId"
            render={({ match }) => {
              const { eventId } = match.params;
              return <VideoChatPage eventId={eventId} />;
            }}
          />
        )}
        <PageLayout pageLoading={loading}>
          <Switch>
            <Route
              path="/clients/:clientId?/:tabId?"
              render={({ match }) => {
                const { clientId, tabId } = match.params;
                return <ClientsPage clientId={clientId} tabId={tabId} />;
              }}
            />

            <Route
              path="/messages/:clientId?"
              render={({ match }) => {
                const { clientId } = match.params;
                return <MessagesPage clientId={clientId} />;
              }}
            />

            <Route
              path="/schedule/:clientId?"
              render={({ match }) => {
                const { clientId } = match.params;
                return <SchedulePage clientId={clientId} />;
              }}
            />
            <Route path="/dashboard" render={() => <DashboardPage />} />
            <Route path="*">
              <Redirect to="/clients" />
            </Route>
          </Switch>
        </PageLayout>
      </Switch>
    </Suspense>
  );
}

export default AuthRequiredRoutes;
