import React from 'react';
import { Flex, Link } from '@chakra-ui/react';
import { NavLink as RouterLink } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { newClientId } from 'sharedHooks/useSaveClient';

function MainNavigation(): JSX.Element {
  const {
    showMessagesLinkFlag,
    showScheduleLinkFlag,
    showClientsDashboardFlag,
  } = useFlags();

  const resetRedirectClientId = () => {
    if (newClientId()) newClientId('');
  };
  return (
    <Flex
      bg="brand.white"
      alignItems="center"
      height="100%"
      paddingX={10}
      color="brand.gray2"
    >
      {showScheduleLinkFlag || showMessagesLinkFlag ? (
        <>
          <Link
            as={RouterLink}
            to="/clients"
            paddingX={10}
            onClick={resetRedirectClientId}
            _activeLink={{ color: 'brand.blue2' }}
          >
            Clients
          </Link>
          {showClientsDashboardFlag && (
            <Link
              as={RouterLink}
              to="/dashboard"
              paddingX={10}
              onClick={resetRedirectClientId}
              _activeLink={{ color: 'brand.blue2' }}
            >
              Dashboard
            </Link>
          )}
          {showMessagesLinkFlag && (
            <Link
              as={RouterLink}
              to="/messages"
              paddingX={10}
              onClick={resetRedirectClientId}
              _activeLink={{ color: 'brand.blue2' }}
            >
              Messages
            </Link>
          )}
          {showScheduleLinkFlag && (
            <Link
              as={RouterLink}
              to="/schedule"
              paddingX={10}
              onClick={resetRedirectClientId}
              _activeLink={{ color: 'brand.blue2' }}
            >
              Schedule
            </Link>
          )}
        </>
      ) : (
        <Link
          as={RouterLink}
          to="/clients"
          paddingX={6}
          onClick={resetRedirectClientId}
        >
          Dashboard
        </Link>
      )}
    </Flex>
  );
}

export default React.memo(MainNavigation);
