import React, { useEffect, useState } from 'react';
import { Flex, Heading, Divider, Tabs, TabList, Tab } from '@chakra-ui/react';
import {
  startOfHour,
  compareAsc,
  startOfDay,
  endOfDay,
  startOfWeek,
  endOfWeek,
} from 'date-fns';
import { useFlags } from 'launchdarkly-react-client-sdk';
import emphasizedDateTime from 'sharedHelpers/emphasizedDateTime';

import useCombinedScheduleEvents, {
  createArrayEventsDashboard,
} from '../hooks/useCombinedScheduleEvents';
import DashboardEventsList from './DashboardEventsList';
import DashboardCalendarCarousel from './DashboardCalendarCarousel';
import EventsDaySelector from './EventsDaySelector';
import type { ClientEvent } from '../../../shared/types/clientTypes';

import '../../../shared/styles/baseline-arrow.css';

const roundedDate = startOfHour(Date.now());
const transformDashboardEvents = (events: ClientEvent[]): ClientEvent[] =>
  [...events].sort((a, b) =>
    compareAsc(emphasizedDateTime(a), emphasizedDateTime(b))
  );

type TabFilters = 'allEvents' | 'myEvents' | 'noAttendance';
const tabFilters: { text: string; value: TabFilters }[] = [
  {
    text: 'All events',
    value: 'allEvents',
  },
  { text: "Events I'm attending", value: 'myEvents' },
  {
    text: 'Attendance not marked',
    value: 'noAttendance',
  },
];

function Dashboard(): JSX.Element {
  const { scheduleDashboardEdgeFlag } = useFlags();
  const defaultFilterIndex = scheduleDashboardEdgeFlag ? 1 : 0;
  const [selectedIndex, setSelectedIndex] = useState(defaultFilterIndex);
  const [weekToLoad, setWeekToLoad] = useState(
    startOfWeek(startOfDay(roundedDate), { weekStartsOn: 1 })
  );
  const [startTimestamp, setStartTimestamp] = useState(startOfDay(roundedDate));
  const [endTimestamp, setEndTimestamp] = useState(endOfDay(roundedDate));
  const [refValue, setRefValue] = useState<number>(0);
  const [attendingFilter, setAttendingFilter] = useState<TabFilters>(
    tabFilters[defaultFilterIndex].value
  );
  const [dashboardEvents, setDashboardEvents] = useState<ClientEvent[]>([]);

  const setDayFilter = (
    day: Date,
    index: number,
    TabsPanelCarousel: HTMLDivElement
  ) => {
    setStartTimestamp(startOfDay(day));
    setEndTimestamp(endOfDay(day));
    if (TabsPanelCarousel?.children) {
      const tabChildrens = Array.prototype.slice.call(
        TabsPanelCarousel?.children
      );
      const DayTabComponentRef = tabChildrens[index];
      setRefValue(
        DayTabComponentRef?.offsetLeft + DayTabComponentRef?.offsetWidth / 2
      );
    }
  };
  const { events, loading } = useCombinedScheduleEvents({
    dashboardDates: { startTimestamp, endTimestamp },
    attendingFilter,
    firstDayOfWeek: weekToLoad,
  });

  useEffect(() => {
    if (!loading) {
      setDashboardEvents(createArrayEventsDashboard(events));
    }
  }, [events]);

  const handleTabsChange = (index: number) => {
    setSelectedIndex(index);
    setAttendingFilter(tabFilters[index].value);
  };

  const scrollRef = React.useRef<HTMLDivElement>(null);

  return (
    <Flex
      direction="column"
      width="100%"
      height="100%"
      paddingY={4}
      paddingX={5}
    >
      <Flex
        direction="row"
        paddingY={2}
        justifyContent="space-between"
        alignItems="baseline"
      >
        <Heading fontSize="2xl" fontWeight="normal" color="brand.blue1">
          Events
        </Heading>
        {scheduleDashboardEdgeFlag && (
          <Tabs
            isLazy
            index={selectedIndex}
            onChange={handleTabsChange}
            variant="secondarySelect"
          >
            <TabList paddingY={0}>
              <Tab>{tabFilters[0].text}</Tab>

              <Divider
                orientation="vertical"
                borderColor="brand.gray5"
                height={5}
                opacity={1}
                width="px"
                alignSelf="center"
              />

              <Tab>{tabFilters[1].text}</Tab>

              <Divider
                orientation="vertical"
                borderColor="brand.gray5"
                height={5}
                opacity={1}
                width="px"
                alignSelf="center"
              />

              <Tab>{tabFilters[2].text}</Tab>
            </TabList>
          </Tabs>
        )}
      </Flex>
      <Divider color="brand.gray7" />
      <Flex
        direction="row"
        padding={2}
        justifyContent="space-between"
        alignItems="baseline"
        background="brand.gray7"
        color="brand.gray2"
        fontSize="14px"
      >
        <EventsDaySelector
          startDate={startOfWeek(weekToLoad, { weekStartsOn: 1 })}
          endDate={endOfWeek(weekToLoad, { weekStartsOn: 1 })}
          setTodayDate={setWeekToLoad}
        />
      </Flex>
      <Flex
        height="100%"
        overflow="hidden"
        ref={scrollRef}
        overflowY="auto"
        direction="column"
      >
        <DashboardCalendarCarousel
          onChange={setDayFilter}
          startDate={weekToLoad}
          setStartDate={setWeekToLoad}
        />
        <Divider
          style={{ left: refValue, marginTop: 14 }}
          className="divider"
          color="brand.gray5"
        />
        <Flex height="100%" marginY={1}>
          <DashboardEventsList
            events={transformDashboardEvents(dashboardEvents)}
            loading={loading}
            scrollRef={scrollRef}
          />
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Dashboard;
